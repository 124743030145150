export default {
    computed: {
        nameErrors() {
            const errors = []
            if (!this.$v.name.$dirty) return errors
            !this.$v.name.required && errors.push('Campo obligatorio.')
            return errors
        },
        addressErrors() {
            const errors = []
            if (!this.$v.address.$dirty) return errors
            !this.$v.address.required && errors.push('Campo obligatorio.')
            return errors
        },
        cityErrors() {
            const errors = []
            if (!this.$v.city.$dirty) return errors
            !this.$v.city.required && errors.push('Campo obligatorio.')
            return errors
        },
        enterpriseErrors() {
            const errors = []
            if (!this.$v.enterprise.$dirty) return errors
            !this.$v.enterprise.required && errors.push('Campo obligatorio.')
            return errors
        },
        vatIdErrors() {
            const errors = []
            if (!this.$v.vat_id.$dirty) return errors
            !this.$v.vat_id.required && errors.push('Campo obligatorio.')
            return errors
        },
        stateErrors() {
            const errors = []
            if (!this.$v.state.$dirty) return errors
            !this.$v.state.required && errors.push('Campo obligatorio.')
            return errors
        },
        postalCodeErrors() {
            const errors = []
            if (!this.$v.postal_code.$dirty) return errors
            !this.$v.postal_code.required && errors.push('Campo obligatorio.')
            return errors
        },
        phoneErrors() {
            const errors = []
            if (!this.$v.phone.$dirty) return errors
            !this.$v.phone.required && errors.push('Campo obligatorio.')
            return errors
        },
        shortNameErrors() {
            const errors = []
            if (!this.$v.short_name.$dirty) return errors
            !this.$v.short_name.required && errors.push('Campo obligatorio.')
            return errors
        },
        pcodeErrors() {
            const errors = []
            if (!this.$v.pcode.$dirty) return errors
            !this.$v.pcode.required && errors.push('Campo obligatorio.')
            return errors
        },
        emailErrors() {
            const errors = []
            if (!this.$v.email.$dirty) return errors
            !this.$v.email.email && errors.push('Debe ser un e-mail válido')
            !this.$v.email.required && errors.push('E-mail es requerido')
            return errors
        },
        checkoutEmailsErrors() {
            const errors = []
            if (!this.$v.email.$dirty) return errors
            !this.$v.checkouts_email.email && errors.push('Debe ser un e-mail válido')
            !this.$v.checkouts_email.required && errors.push('E-mail es requerido')
            return errors
        },
        passwordErrors() {
            const errors = []
            if (!this.$v.password.$dirty) return errors
            !this.$v.password.required && errors.push('Contraseña obligatoria')
            !this.$v.password.minLength && errors.push('Debe tener al menos 6 caracteres')
            return errors
        },
        repeatPasswordErrors() {
            const errors = []
            if (!this.$v.repeatPassword.$dirty) return errors
            !this.$v.repeatPassword.sameAsPassword && errors.push('La contraseña debe coincidir')
            return errors
        },
        licenseErrors() {
            const errors = []
            if (!this.$v.license.$dirty) return errors
            !this.$v.license.required && errors.push('Licencia obligatoria.')
            return errors
        },
    }
}
