<template>
  <div>


    <v-container>
      <v-row>
        <v-col cols="12" md="5" class="text-left">
          <h3 class="mb-5">Verifique la dirección de tu local</h3>
        </v-col>
        <v-col cols="12" md="7">
          <v-form>
            <v-col sm="12">
              <v-text-field
                            v-model="address"
                            placeholder=" "
                            label="Dirección" required
                            append-outer-icon="search"
                            @click:append-outer="clickSearch"
              >
              </v-text-field>
            </v-col>
            <div v-if="addressList">
              <div v-for="(item, index) in addressList"
                   :key="index">
                <v-card @click="addressSelected(item)">
                  <v-card-text>
                    {{item.long_address}}
                  </v-card-text>
                </v-card>
                <br>
              </div>
            </div>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="mt-6">
      <v-row>
        <v-col cols="12" md="3">
          <v-btn @click="$emit('back')">Anterior</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import errorsMixin from "../../mixins/errors";

export default {
  name: "SearchLocalAddress",
  mixins: [validationMixin, errorsMixin],
  data: () => ({
    address:'',
    addressList:[]
  }),
  props: {
    value: {
      required: true
    }
  },
  validations: {},
  mounted() {
    this.address = this.value.business.address
  },
  methods: {
    async clickSearch() {
      const query = `${this.address || ''}`
      const res = (await this.$http.get(`/v2/utils/address/locations?query=${query}`)).data
      this.addressList = res.map((item) => {
            let res_obj = {
              long_address:item.formatted_address,
              lat:item.geometry.location.lat,
              lng:item.geometry.location.lng
            }
            console.log(item.address_components);
            item.address_components.map(address_component => {
              switch (address_component.types[0]) {
                case 'street_number':
                  res_obj.address = address_component.long_name || address_component
                  break;
                case 'route':
                  res_obj.address = `${(address_component.long_name || address_component )} ${res_obj?.address || ''}`
                  break;
                case 'administrative_area_level_2':
                  res_obj.state = address_component.long_name || address_component
                  break;
                case 'locality':
                  res_obj.city = address_component.long_name || address_component
                  break;
                case 'country':
                  res_obj.country = address_component.long_name || address_component
                break;
                case 'postal_code':
                  res_obj.postal_code = address_component.long_name || address_component
                  break;
              }
            })
            return res_obj
      })
    },
    addressSelected(address_obj) {
      this.value.business.address = address_obj.address;
      this.value.business.postal_code = address_obj.postal_code;
      this.value.business.city = address_obj.city;
      this.value.business.country = address_obj.country;
      this.value.business.state = address_obj.state;
      this.value.business.lat = address_obj.lat;
      this.value.business.lng = address_obj.lng;
      this.addressList = null;
      this.submit();
    },
    submit() {
      this.$v.$touch()
      this.$emit("next")
      this.$emit("input", this.value)
    },
  }
}
</script>
